import React, { Component } from "react"
import { Link } from "gatsby"
import Slider from "react-slick"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"

import sie from "../../images/about/our-leader-team/Artboard 1.webp"
import nakkiran from "../../images/about/our-leader-team/Artboard 2.webp"
import jason from '../../images/about/our-leader-team/Jason Chan.webp'
import catherine from '../../images/about/our-leader-team/Catherine Natt.webp'
import ong from '../../images/about/our-leader-team/Ong Lay Fung.webp'
import christianlisangan from '../../images/about/our-leader-team/Christian Lisangan.webp'
import paritosh from '../../images/about/our-leader-team/Paritosh Keertikar.webp'
import sven from '../../images/about/our-leader-team/Sven Tang.webp'

import seng from '../../images/about/our-leader-team/Tan Seng Chuen.webp'

import surya from "../../images/about/our-leader-team/Surya Dharma.webp"
import devina from "../../images/about/our-leader-team/Devina.webp"
import julito from "../../images/about/our-leader-team/Julito Pardamean Siahaan.webp"
import amal from "../../images/about/our-leader-team/Amalia Indri Senoadji.webp"
import jimmy from "../../images/about/our-leader-team/Jimmy Susanto.webp"
import thomas from "../../images/about/our-leader-team/Whisnu Thomas Julianto.webp"
import steven from '../../images/about/our-leader-team/Steven Amadeus.webp'

import fathi from "../../images/about/our-leader-team/Fathi Amin.webp"

import dummy from '../../images/about/our-leader-team/dummy.png'
// import pic3 from "../../images/about/our-leader-team/Artboard 3.webp"
// import pic4 from "../../images/about/our-leader-team/Artboard 4.webp"

// import pic5 from "../../images/about/our-leader-team/Artboard 5.webp"
// import pic6 from "../../images/about/our-leader-team/Artboard 6.webp"
// import pic7 from "../../images/about/our-leader-team/Artboard 7.webp"
// import pic8 from "../../images/about/our-leader-team/Artboard 8.webp"

// import pic9 from "../../images/about/our-leader-team/Artboard 9.webp"
// import pic10 from "../../images/about/our-leader-team/Artboard 10.webp"
// import pic11 from "../../images/about/our-leader-team/Artboard 11.webp"
// import pic12 from "../../images/about/our-leader-team/Artboard 12.webp"

// import pic13 from "../../images/about/our-leader-team/Artboard 13.webp"
// import pic14 from "../../images/about/our-leader-team/Artboard 14.webp"
// import pic15 from "../../images/about/our-leader-team/Artboard 15.webp"

const content = [
  {
    image: sie,
    name: "Sie Djohan",
    title: "Kalbe Genexine Biologics CEO",
    idDetail: 1,
  },
  {
    image: nakkiran,
    name: "Nakkiran Saravanakumar",
    title: "Director",
    idDetail: 2,
  },
  {
    image: sven,
    name: "Sven Tang",
    title: "CMO",
    idDetail: 3,
  },
  {
    image: seng,
    name: "Tan Seng Chuen",
    title: "VP, Global Head of MA & CD",
    idDetail: 4,
  },
  {
    image: catherine,
    name: "Catherine Natt",
    title: "Supply Chain and QA GM",
    idDetail: 5,
  },
  {
    image: paritosh,
    name: "Paritosh Keertikar",
    title: "Assistant Director - Clinical Affairs",
    idDetail: 6,
  },
  {
    image: jimmy,
    name: "Jimmy Susanto",
    title: "Business Development GM (Affiliates)",
    idDetail: 11,
  },
  {
    image: julito,
    name: "Julito Pardamean Siahaan",
    title: "GM Business (Affiliates)",
    idDetail: 12,
  },
]

class TeamSliderCustom extends Component {
  render() {
    const settings = {
      // dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 3,
      slidesToScroll: 3,
      autoplay: false,
      responsive: [
        {
          breakpoint: 2560,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
          },
        },
        {
          breakpoint: 1440,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
          },
        },
        {
          breakpoint: 1200,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
          },
        },
        {
          breakpoint: 991,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
          },
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
      ],
    }
    return (
      <>
        <Slider
          {...settings}
          className="dlab-team11-area dots-theme dots-long d-primary btn-style-1"
        >
          {/* {content.map((item, id) => (
                        <div key={id} class="item wow fadeInUp" data-wow-duration="2s" data-wow-delay="0.2s">
                            <div class="dlab-box dlab-team11">
                                <div class="dlab-media dlab-img-effect custom-size">                                   
                                    <img src={item.image} alt="team-img" />
                                </div>
                                <div class="dlab-info">
                                    <h4 class="dlab-title">{item.name}</h4>
                                    <span class="dlab-position">{item.title}</span>
                                </div>
                            </div>
                        </div>
                    ))} */}
          {content.map((item, id) => {
            console.log(item)
            return (
              <div
                key={id}
                class="item wow fadeInUp"
                data-wow-duration="2s"
                data-wow-delay="0.2s"
              >
                <div class="dlab-box dlab-team11">
                  <div class="dlab-media dlab-img-effect custom-size">
                    {/* {item.idDetail === 5 || item.idDetail === 9 ? (
                      <img
                        src={item.image}
                        alt="team-img"
                        style={{ transform: "scaleX(-1)" }}
                      />
                    ) : (
                      <img src={item.image} alt="team-img" />
                    )} */}
                    <img src={item.image} alt="team-img" />
                  </div>
                  <div class="dlab-info">
                    <h4 class="dlab-title">{item.name}</h4>
                    <span class="dlab-position">{item.title}</span>
                  </div>
                </div>
              </div>
            )
          })}
        </Slider>
      </>
    )
  }
}
export default TeamSliderCustom
