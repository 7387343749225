import React, { useState, Component } from "react"
import { Link, useStaticQuery, graphql } from "gatsby"
import Header from "../layouts/header"
import Footer from "../components/custom-components/FooterCream"
import HomeSliderCustom from "../components/custom-components/homeslider-custom"
import {
  homeSliderContent2,
  servicesContentCustom1,
  servicesContentCustom2,
} from "../components/SliderContent"
import BannerHeader from "../components/custom-components/BannerHeader"
// import bg from "../images/products-and-services/header-products-and-services.webp"
import bg from "../images/products-and-services/header-about.jpg"
import ServicesSliderCustom from "../components/custom-components/serviceslider-custom"
import ServicesSliderCustom2 from "../components/custom-components/serviceslider-custom2"
import ProjectSlider2 from "../components/projectSlider2"
import OthersCompanies from "../components/custom-components/OthersCompanies"
import { StaticImage, GatsbyImage, getImage } from "gatsby-plugin-image"

import "../css/plugins.css"
import "../css/style.css"
import "../css/skin/skin-1.css"
import "../css/templete.min.css"
import "../css/custom-css/custom-about.css"

import ScrollToTop from "../components/scrollToTop"
import pic1 from "../images/about/about-kalbio-1.webp"
import pic2 from "../images/about/about-kalbio-2.webp"
import TeamSliderCustom from "../components/custom-components/teamslide-custom"
import TeamSliderCustom2 from "../components/custom-components/teamslide-custom2"
import FloatingBtn from "../components/custom-components/floatingBtn"
import ModalVideo from "react-modal-video"
import Seo from "../components/seo"

// export const query = graphql`
//   query About {
//     mission: contentfulAboutKalbioImages(title: { eq: "mission" }) {
//       image {
//         gatsbyImageData
//       }
//     }
//     vision: contentfulAboutKalbioImages(title: { eq: "vision" }) {
//       image {
//         gatsbyImageData
//       }
//     }
//     KGbio: contentfulOurStakeholdersImages(title: { eq: "KGbio" }) {
//       image {
//         gatsbyImageData
//       }
//     }
//     Kalbe: contentfulOurStakeholdersImages(title: { eq: "Kalbe" }) {
//       image {
//         gatsbyImageData
//       }
//     }
//     IGK: contentfulOurStakeholdersImages(title: { eq: "IGK" }) {
//       image {
//         gatsbyImageData
//       }
//     }
//     one: contentfulAboutKalbioImages(title: { eq: "one" }) {
//       image {
//         gatsbyImageData
//       }
//     }
//     two: contentfulAboutKalbioImages(title: { eq: "two" }) {
//       image {
//         gatsbyImageData
//       }
//     }
//     three: contentfulAboutKalbioImages(title: { eq: "three" }) {
//       image {
//         gatsbyImageData
//       }
//     }
//     four: contentfulAboutKalbioImages(title: { eq: "four" }) {
//       image {
//         gatsbyImageData
//       }
//     }
//     five: contentfulAboutKalbioImages(title: { eq: "five" }) {
//       image {
//         gatsbyImageData
//       }
//     }
//   }
// `

export const query = graphql`
  query About {
    mission: contentfulAboutIgkImages(title: { eq: "mission" }) {
      image {
        gatsbyImageData
      }
    }
    vision: contentfulAboutIgkImages(title: { eq: "vision" }) {
      image {
        gatsbyImageData
      }
    }
    KGbio: contentfulOurStakeholdersImages(title: { eq: "KGbio" }) {
      image {
        gatsbyImageData
      }
    }
    Kalbe: contentfulOurStakeholdersImages(title: { eq: "Kalbe" }) {
      image {
        gatsbyImageData
      }
    }
    IGK: contentfulOurStakeholdersImages(title: { eq: "IGK" }) {
      image {
        gatsbyImageData
      }
    }
  }
`

const Index = () => {
  const [isOpen, setOpen] = useState(false)

  const {
    // one,
    // two,
    // three,
    // four,
    // five,
    mission,
    vision,
    KGbio,
    Kalbe,
    IGK,
  } = useStaticQuery(query)

  return (
    <div className="skin-1">
      <Seo title="About Us" />

      <Header aboutPage />
      <FloatingBtn />
      <BannerHeader background={bg} title="About Us" />
      {/* <HomeSliderCustom data={homeSliderContent2} /> */}

      <div id="innogene" class="page-content bg-white">
        <div class="section-full content-inner bg-white">
          <div class="container" style={{ marginTop: "3rem" }}>
            <h2 class="title-abt">About Innogene Kalbiotech</h2>
            <p class="abt-text text-left responsiveText">
              Innogene Kalbiotech runs clinical trials and research activities,
              and also manages the application of patents and commercialisation
              of its products worldwide. Specialising in oncology and
              therapeutic, Innogene was incorporated in 2003 as a subsidiary of
              PT Kalbe Farma, Tbk., the largest publicly-listed pharmaceutical
              company in Southeast Asia. Kalbe was established in 1966 and named
              as one of the 2014 “Most Admired Companies” by Fortune Indonesia.
            </p>
            <br />
            <p class="abt-text text-left responsiveText">
              Innogene Kalbiotech is in a unique position to enrich diagnostics,
              therapeutics and healthcare by bringing in the latest
              breakthroughs in biology and biotechnology. We have a proven track
              record in successfully bringing products to market in Asia through
              clinical development to regulatory compliance and
              commercialization. That's because we understand what the unmet
              needs are in the highly fragmented markets in the region. As our
              partner, you will have access to our unrivalled business
              capabilities in marketing and branding, and commercialization
              through our extensive networks. Above all, our reputation as a
              well-respected and trusted research and technology partner is a
              mark of assurance for quality, reliability and protection of
              intellectual property rights. While huge disparities in the
              diverse countries and communities pose many challenges, they also
              offer many opportunities. With our expertise, experience and
              familiarity with the regulatory environment, we can help you
              navigate the uncharted waters and de-mystify this complex market
              that is Asia. Our deep insights and capabilities, coupled with
              excellent supply chain connectivity, will give you first-mover
              advantage to get a foothold quickly in the Asian markets.
            </p>
            {/* <h2 class="title-abt-2 text-center">Corporate Culture</h2> */}

            {/* <div class="section-full bg-white content-inner-jsv">
              <div class="container">
                <div class="row">
                  <div class="col">
                    <div class="section-content box-sort-in button-example p-b0">
                      <div class="row justify-content-center">
                        <div class="col">
                          <div class="blog-post blog-md clearfix border-1 br-col-b1 radius-sm vision custom-box">
                            <div class="dlab-post-info vision abt-info">
                              <div class="custom-desc">
                                <p class="p-t15 text-white custom-para custom-line-height ">
                                  Trust
                                  <br />
                                  is the glue
                                  <br />
                                  of life
                                </p>
                              </div>

                              <div class="custom-icon-number icon-one">
                                <GatsbyImage
                                  image={getImage(one.image)}
                                  alt="one-img"
                                />
                              </div>
                            </div>
                          </div>
                        </div>

                        <div class="col">
                          <div class="blog-post blog-md clearfix border-1 br-col-b1 radius-sm mission custom-box">
                            <div class="dlab-post-info mission abt-info">
                              <div class="custom-desc">
                                <p class="p-t15 text-white custom-para custom-line-height">
                                  Mindfullness
                                  <br />
                                  is the foundation
                                  <br />
                                  of our action
                                </p>
                              </div>
                              <div class="custom-icon-number">
                                <GatsbyImage
                                  image={getImage(two.image)}
                                  alt="two-img"
                                />
                              </div>
                            </div>
                          </div>
                        </div>

                        <div class="col">
                          <div class="blog-post blog-md clearfix border-1 br-col-b1 radius-sm vision custom-box">
                            <div class="dlab-post-info vision abt-info">
                              <div class="custom-desc">
                                <p class="p-t15 text-white custom-para custom-line-height">
                                  Innovation
                                  <br />
                                  is the key
                                  <br />
                                  to our success
                                </p>
                              </div>
                              <div class="custom-icon-number">
                                <GatsbyImage
                                  image={getImage(three.image)}
                                  alt="three-img"
                                />
                              </div>
                            </div>
                          </div>
                        </div>

                        <div class="col">
                          <div class="blog-post blog-md clearfix border-1 br-col-b1 radius-sm vision custom-box">
                            <div class="dlab-post-info vision abt-info">
                              <div class="custom-desc">
                                <p class="p-t15 text-white custom-para custom-line-height">
                                  Strive to
                                  <br />
                                  be the best
                                </p>
                              </div>
                              <div class="custom-icon-number icon-four">
                                <GatsbyImage
                                  image={getImage(four.image)}
                                  alt="four-img"
                                />
                              </div>
                            </div>
                          </div>
                        </div>

                        <div class="col">
                          <div class="blog-post blog-md clearfix border-1 br-col-b1 radius-sm mission custom-box">
                            <div class="dlab-post-info mission abt-info">
                              <div class="custom-desc">
                                <p class="p-t15 text-white custom-para custom-line-height">
                                  Interconnectedness
                                  <br />
                                  is a universal
                                  <br />
                                  way of life
                                </p>
                              </div>
                              <div class="custom-icon-number">
                                <GatsbyImage
                                  image={getImage(five.image)}
                                  alt="five-img"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div> */}

            {/* <div class="container">
              <h2 class="title-abt">Leading the way in Asia</h2>
              <div class="row align-items-center">
                <div
                class="col-lg-6 col-md-12 m-b30 wow fadeInLeft paddingText"
                data-wow-duration="2s"
                data-wow-delay="0.3s"
              >
                <div class="our-story">
                  <h2 class="story-custom">Why Asia</h2>

                  <p class="abt-text">
                    Consider these facts: More than half the world's population
                    lives in Asia. Asia’s two rising economic powerhouses, China
                    and India, and the emerging ASEAN economies continue to
                    record strong growth. A rising Asian middle class and
                    growing healthcare needs – basic vaccines and primary
                    healthcare at one end, and high-end products and services at
                    the other end of the spectrum – present great potential in
                    untapped markets in the region. Clearly, Asia is the new
                    frontier for the latest breakthroughs in biology and
                    biotechnology. Innogene Kalbiotech is in a unique position
                    to enrich diagnostics, therapeutics and healthcare for
                    emerging markets in Asia. That's because we understand what
                    the unmet needs are in the highly fragmented markets in the
                    region. While huge disparities in the diverse countries and
                    communities pose many challenges, they also offer many
                    opportunities. With our expertise, experience and
                    familiarity with the regulatory environment, we can help you
                    navigate the uncharted waters and de-mystify this complex
                    market that is Asia. Our deep insights and capabilities,
                    coupled with excellent supply chain connectivity, will give
                    you first-mover advantage to get a foothold quickly in the
                    Asian markets.
                  </p>
                </div>

                <div class="our-story">
                  <h2 class="story-custom">How We Can Help</h2>
                  <ul className="list-unstyled">
                    <div>
                      <h6>Your Preferred Partner in Asia</h6>
                      <p>
                        We have a proven track record in successfully bringing
                        products to market in Asia -- from clinical development
                        to regulatory compliance and commercialization. As our
                        partner, you will have access to our unrivalled business
                        capabilities in marketing and branding, and distribution
                        through our extensive networks. Above all, our
                        reputation as a well-respected and trusted research and
                        technology partner is a mark of assurance for quality,
                        reliability and protection of intellectual property
                        rights.
                      </p>

                      <h6>Comprehensive Healthcare Solutions Provider</h6>
                      <p>
                        Innogene Kalbiotech is a subsidiary of Kalbe, which has
                        some 50 years of experience in building global brands
                        ranging from pharmaceuticals and supplements to medical
                        devices and healthcare services. Awarded the 'Most
                        Admired Companies 2014' by Fortune Indonesia, Kalbe has
                        more than 20 subsidiaries and 10 production facilities,
                        supported by some 17,000 employees and 6,000 sales and
                        marketing personnel spread out over 68 branches across
                        Indonesia. We have set our sights on becoming a market
                        leader in the region, focusing on Asian and emerging
                        market health issues and launching unique products,
                        solutions and platforms across Asia.
                      </p>

                      <h6>Come Talk to Us</h6>
                      <p>
                        Let us show you how we can help you unlock the potential
                        of your latest innovation and open up exciting
                        opportunities in Asia!
                      </p>
                    </div>
                  </ul>
                </div>
              </div>
              </div>
              <div
                class="col-lg-6 col-md-12 m-b30 our-story-thum wow fadeInRight"
                data-wow-duration="2s"
                data-wow-delay="0.3s"
              >
                <img src={pic1} alt="about-img"></img>
              </div>
            </div> */}

            <div class="row align-items-center">
              {/* <div
                class="col-lg-6 col-md-12 m-b30 our-story-thum wow fadeInRight"
                data-wow-duration="2s"
                data-wow-delay="0.3s"
              >
                <img src={pic2} alt="about-img"></img>
              </div> */}

              {/* <div
                class="col-lg-6 col-md-12 m-b30 wow fadeInLeft"
                data-wow-duration="2s"
                data-wow-delay="0.3s"
              > */}
              {/* <div class="our-story"> */}
              {/* <h2 class="title">About Kalbio Global Medika</h2> */}
              {/* <p class="abt-text">
                    We are an Innovative CDMO, equipped with CMC and scale-up
                    capabilities plugged into Boston biotech ecosystem and
                    state-of-the-art facility to enhance efficiency and
                    productivity. KGM provides end-to-end services with an
                    assurance of product quality. In support of Indonesian
                    government program, our intent and focus are to have maximum
                    locally manufactured content in the medicinal raw materials
                    and biological pharmaceutical products. Our current pipeline
                    includes several biosimilars and novel biologics. With API
                    manufacturing, formulation, fill and finish capabilities
                    averaging 10 million units of syringes, cartridges, and
                    vials, we supply local and international markets. KGM is an
                    eco-friendly facility; we utilize solar panels and chiller
                    plant automation system to reduce greenhouse gas emissions.
                  </p> */}
              {/* </div> */}
              {/* </div> */}
            </div>
          </div>
        </div>
      </div>

      <div class="section-full bg-white content-inner-jsv">
        <div class="container">
          <div class="row">
            <div class="col-lg-12">
              <div class="section-content box-sort-in button-example p-b0">
                <div class="row">
                  <div class="col-md-6 col-lg-6">
                    <div class="blog-post blog-md clearfix border-1 br-col-b1 radius-sm vision">
                      <div class="dlab-post-info vision">
                        <div class="dlab-post-title">
                          <h4 class="post-title p-t20 p-b0  text-white">
                            Vision
                          </h4>
                        </div>
                        <div class="custom-desc">
                          <p class="p-b20 p-r30 text-white custom-para">
                            To be the best Asian Biosciences Company which is
                            driven by Innovation, Quality and Excellent
                            Management.
                          </p>
                        </div>
                        <div class="custom-icon-vision">
                          <GatsbyImage
                            image={getImage(vision.image)}
                            alt="vision-img"
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="col-md-6 col-lg-6">
                    <div class="blog-post blog-md clearfix border-1 br-col-b1 radius-sm mission">
                      <div class="dlab-post-info mission">
                        <div class="dlab-post-title">
                          <h4 class="post-title p-t20 p-b0 text-white">
                            Mission
                          </h4>
                        </div>
                        <div class="custom-desc">
                          <p class="p-b20 p-r30 text-white custom-para">
                            To improve health for a better life by providing
                            high quality and accessible biopharmaceutical
                            products.
                          </p>
                        </div>
                        <div class="custom-icon-mission">
                          <GatsbyImage
                            image={getImage(mission.image)}
                            alt="mission-img"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <div className="content-block">
        <div class="section-full content-inner bg-gray video-section">
          <div class="container">
            <div class="section-content">
              <div class="col-lg-12 col-md-12 m-b30">
                <div class="video-bx">
                  <StaticImage
                    class="center-img-custom"
                    src="../images/about/thumbnail-about-us.webp"
                    alt="video-thumb-img"
                  />
                  <div class="video-play-icon">
                    <a
                      href="javascript:void(0)"
                      onClick={() => setOpen(true)}
                      class="popup-youtube video bg-youtube"
                    >
                      <i class="fa fa-play"></i>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {typeof window !== "undefined" && (
        <ModalVideo
          channel="youtube"
          allowFullScreen
          isOpen={isOpen}
          videoId="9MdNcQRUAm4"
          onClose={() => setOpen(false)}
        />
      )} */}

      {/* <div id="our-partners" class="page-content frame-box m-b50">
        <div class="container container-custom">
          <div class="section-full content-inner-1 frame-project-area">
            <div class="section-head text-center">
              <h2 class="title">Our Partners</h2>
            </div>
            <ProjectSlider2 />
          </div>
        </div>
      </div> */}

      {/* <div id="qualifications" class="page-content bg-gray">
        <div class="content-block bg-white">
          <div class="section-full bg-gray content-inner about-carousel-ser">
            <div class="container">
              <div class="section-head text-center">
                <h2 class="title">Qualifications</h2>
              </div>

              <ServicesSliderCustom
                slidesToShow={3}
                data={servicesContentCustom1}
              />
            </div>
          </div>
        </div>
      </div> */}

      <div id="recognitions" class="page-content bg-white">
        <div class="content-block bg-white">
          <div class="section-full bg-white content-inner about-carousel-ser">
            <div class="container">
              <div class="section-head text-center">
                <h2 class="title">Recognitions</h2>
              </div>
              <ServicesSliderCustom2
                slidesToShow={1}
                data={servicesContentCustom2}
              />
            </div>
          </div>
        </div>
      </div>

      <div id="our-leader" class="page-content bg-gray">
        <div class="content-block area-frame bg-gray">
          <div class="container-custom-jsv">
            <div class="section-full content-inner-1 bg-gray lead-custom">
              <div class="container-custom-jsv2">
                <div class="section-head text-center">
                  <h2 class="title">Meet Our Leadership Team</h2>
                </div>
                <TeamSliderCustom />
              </div>
            </div>
            <div id="our-depthead" class="section-full content-inner-1 bg-gray lead-custom">
              <div class="container-custom-jsv2">
                <div class="section-head text-center">
                  <h2 class="title">Meet Our Department Heads</h2>
                </div>
                <TeamSliderCustom2 />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div id="other-companies" class="page-content bg-white">
        <div class="content-block">
          <div class="section-full content-inner-2 bg-white">
            <div class="container">
              <div class="section-head style2 text-center">
                <h2 class="title">Our Stakeholders</h2>
              </div>

              <OthersCompanies />
            </div>
          </div>
        </div>
      </div>

      <Footer hideContactInfo />
      <ScrollToTop className="icon-up" />
    </div>
  )
}

export default Index
