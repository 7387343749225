import React, { Component } from 'react';
import { Link } from 'gatsby';
import Slider from "react-slick";
import responsive from '../slider-resonsive'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";



class ServicesSliderCustom2 extends Component {

  render() {

    const settings = {
      dots: false,
      infinite: true,
      speed: 500,
      slidesToShow: 2,
      slidesToScroll: 1,
      autoplay: false,
      responsive: [
        {
          breakpoint: 1200,
          settings: {
            slidesToShow: 1
          }
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 1
          }
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1
          }
        }
      ]
    };
    return (
      <>
        <Slider {...settings} className="about-ser-carousel btn-style-1">
          {this.props.data.map((item, id) => (
            <div key={id} class="item wow fadeInUp" data-wow-duration="2s" data-wow-delay="0.3s">
              <div class="dlab-box service-media-bx recog-custom">
                <div class="dlab-media dlab-img-effect zoom">
                  <img class="slider-img"src={item.image} alt="recognition-img" />
                </div>
                <div class="dlab-info text-center">
                  <h2 class="dlab-title">{item.serviceName}</h2>
                  <p>{item.description}</p>
                </div>
              </div>
            </div>
          ))}
        </Slider>

      </>
    )
  }
}
export default ServicesSliderCustom2;