import React, { Component } from "react"
import { Link } from "gatsby"
import Slider from "react-slick"
import responsive from "./slider-resonsive"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"

import pic1 from "../images/about/our-partner//bwt.webp"
import pic2 from "../images/about/our-partner/cytiva.webp"
import pic3 from "../images/about/our-partner/gan-and-lee.webp"
import pic4 from "../images/about/our-partner/genexine.webp"
import pic5 from "../images/about/our-partner/kalbe.webp"
import pic6 from "../images/about/our-partner/mAbxience.webp"
import pic7 from "../images/about/our-partner/sartorius.webp"
import pic8 from "../images/about/our-partner/korber.webp"
import pic9 from "../images/about/our-partner/kexing-biopharm.webp"
import pic10 from "../images/about/our-partner/general-atlantic.webp"
import pic11 from "../images/about/our-partner/kalbe-international.webp"

const content = [
  // {
  //     image: pic10,
  //     title: 'General Atlantic',
  //     link: 'https://www.generalatlantic.com/'
  // },
  {
    image: pic5,
    title: "Kalbe Farma",
    link: "https://www.kalbe.co.id/",
  },
  {
    image: pic11,
    title: "Kalbe International",
    link: "https://kalbeinternational.com/",
  },
  {
    image: pic9,
    title: "Shandong Kexing",
    link: "http://www.kexing.com/eindex.html",
  },
  {
    image: pic3,
    title: "Gan & Lee",
    link: "http://www.ganlee.com/en/",
  },
  {
    image: pic6,
    title: "mAbxience",
    link: "https://www.mabxience.com/",
  },
  {
    image: pic1,
    title: "BWT",
    link: "http://www.bwt-pharma.com/",
  },
  {
    image: pic8,
    title: "Korber Pharma Inspection",
    link: "https://www.koerber-pharma.com/en/solutions/inspection",
  },
  // {
  //     image: pic7,
  //     title: 'Sartorius',
  //     link: 'https://www.sartorius.com/en'
  // },
  {
    image: pic2,
    title: "Cytiva",
    link: "https://www.cytivalifesciences.com/en/ca",
  },
  // {
  //     image: pic4,
  //     title: 'Genexine',
  //     link: 'http://www.genexine.com/'
  // },
]

class ProjectSlider2 extends Component {
  render() {
    const settings = {
      dots: false,
      infinite: true,
      speed: 500,
      slidesToShow: 3,
      slidesToScroll: 1,
      autoplay: false,
      responsive: responsive(),
    }
    return (
      <>
        <Slider {...settings} className="btn-style-2">
          {content.map((item, id) => (
            <div
              key={id}
              class="item wow fadeInUp"
              data-wow-duration="2s"
              data-wow-delay="0.3s"
            >
              <div class="project-bx">
                <div class="media dlab-img-effect ">
                  <img src={item.image} alt="logo-img" />
                </div>
                <div class="info">
                  <a href={item.link} target="_blank" rel="noopener noreferrer">
                    <h4 class="title">{item.title}</h4>
                  </a>
                </div>
              </div>
            </div>
          ))}
        </Slider>
      </>
    )
  }
}
export default ProjectSlider2
